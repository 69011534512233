import { ChargeDetailConstant } from "../ChargeDetailMaintenance/ChargeDetailConstant";
import { ChargeConstant } from "../ChargeHeader/ChargeConstant";

export const ChangeKeyDataConstant = {
    Charge: {
        CHARGE_DETAIL: 'Charge Detail',
        CHANGE_KEY_DATA: 'Change Of Key Data',
        PROCESS_STATUS: 'Handling Status',
        CNTR_NO: ChargeConstant.Charge.CNTR_NO,
        OLD_CNTR_NO: 'Old Container No.',
        HANDING_TML:ChargeConstant.Charge.HANDLING_TML,
        VESSEL_CODE: ChargeConstant.Charge.VESSEL_CODE,
        VOYAGE_CODE: ChargeConstant.Charge.VOYAGE_CODE,
        NEW_VESSEL: 'New Co Code/Vsl Code/Voy Code/ETD',
        OLD_VESSEL: 'Old Co Code/Vsl Code/Voy Code/ETD',
        BILL_CODE: 'Bill Code',
        OLD_BILL_CODE: 'Old Bill Code',
        FIRST_IN_TML: 'First In Tml DateTime',
        OLD_FIRST_IN_TML: 'Old First In Tml DateTime',
        OLD_ETB: 'Old ETB',
        NEW_ETB: 'New ETB',
        OLD_SERVICE_CODE: 'Old Service Code',
        NEW_SERVICE_CODE: 'New Service Code',
        CREATED_DATE: 'Created Date',
        CREATED_DATE_RANGE: 'Created Date Range',
        COKD_TYPE: 'COKD Type',
        COKD_SUB_TYPE: 'COKD Sub Type',
        COKD_OPS_DATE: 'COKD OPS Date',
        CNTR_SIZE: 'Cntr Size',
        OLD_CNTR_SIZE: 'Old Cntr Size',
        OLD_FINAL_OUT_DATE_TIME: 'Old Final Out Tml DateTime',
        NEW_FINAL_OUT_DATE_TIME: 'Final Out Tml DateTime',
        OLD_TRANS_STATUS: 'Old Transportation Status',
        NEW_TRANS_STATUS: 'Transportation Status',
        OLD_OOG: 'Old OOG',
        NEW_OOG: 'OOG',
        OLD_EMPTY_IND: 'Old Empty Indicator',
        NEW_EMPTY_IND: 'Empty Indicator',
        OLD_IMO: 'Old IMO',
        NEW_IMO: 'IMO',

        DTL_STATE: ChargeConstant.Charge.STATE,
        CHARGE_TYPE: ChargeConstant.Charge.CHARGE_TYPE,
        SUB_CHARGE_TYPE: ChargeConstant.Charge.SUB_CHARGE_TYPE,
        CHARGE_ON_COMPANY: ChargeConstant.Charge.CHARGE_ON_COMPANY,
        BILL_TO_COMPANY: ChargeConstant.Charge.BILL_TO_COMPANY,
        ALTERABLE_BILL_TO_COMPANY: ChargeConstant.Charge.ALTERABLE_BILL_TO_COMPANY,
        ALTERABLE_CHARGE_ON_COMPANY: ChargeConstant.Charge.ALTERABLE_CHARGE_ON_COMPANY,
        OPERATION_TYPE: ChargeConstant.Charge.OPERATION_TYPE,
        TARIFF_CODE: ChargeConstant.Charge.TARIFF_CODE,
        OPS_DATE: ChargeConstant.Charge.OPS_DATE,

        CHANGE_KEY_DATA_STATE_LEGEND: 'State Legend',
        STATE_RED: 'FAIL/FAILED/SG/SA/E',
        STATE_GREY: 'COMPLETED/I/DI/T/MT',
        STATE_GREEN: 'NEW/R/F',
        STATE_BLUE: 'Others',

        CHARGE_TITLE: ChargeDetailConstant.Charge.CHARGE_TITLE,
        APPLY_BUT: ChargeDetailConstant.Charge.APPLY_BUT,
        EDIT_TITLE: 'EDIT',
        SEARCH_CRITERIA: ChargeDetailConstant.Charge.SEARCH_CRITERIA,

    }
}