import { ChangeKeyDataConstant } from "presentation/constant/ChangeKeyData/ChangeKeyDataConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { memo, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { ChangeKeyDataEditForm } from "./ChangeKeyDataEditForm";


const ChangeKeyDataEditPanel = () => {
  const changeKeyDataVM = useChangeKeyDataVM();
  const messageBarVM = useMessageBarVM();
  const [changeKeyDataState] = useChangeKeyDataTracked();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {selectedChangeKeyDatas,searchCriteria,allFormState,updateHandlingStatus} = changeKeyDataState;
  const saveButtonClicked = async() => {
    setIsLoading(true);
    await changeKeyDataVM.onEditSaveClick(updateHandlingStatus,selectedChangeKeyDatas).then((data) => {
        if (allFormState && allFormState["batchUpdteFail"]) {
            messageBarVM.showError(allFormState["batchUpdteFail"]?.toString());
        } else {
            messageBarVM.showSuccess(MessageConstant.common.UPDATED_DATA_SUCCESSFUL);
        }
        changeKeyDataVM.getChangeKeyDatas(searchCriteria);
        setIsLoading(false);
    }).catch(() => {
        setIsLoading(false);
    })
  }

  const closeButtonClicked = async() => {
    changeKeyDataVM.onEditCloseClick(selectedChangeKeyDatas);
  }


  return (
    <div className='side-form-content-wrapper'>
        <div className={'flex-row'}>
            <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                    <SidebarTitle>
                        {ChangeKeyDataConstant.Charge.EDIT_TITLE}
                    </SidebarTitle>
                    <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={closeButtonClicked} />
                        </SidebarActionCross>
                </Sidebarheader>
            </div>
        </div>
        {isLoading && 
                <Loader Indicator="Spinner" size="Medium"/>}
        {!isLoading && <>   
        <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <ChangeKeyDataEditForm/>
                </div>
            </div>
        </div>
        <SidebarActionBar>
            <HPHButton label={'Close'} size={'Small'} theme={'Secondary'} onClick={closeButtonClicked} />
            <HPHButton label={'Save'} size={'Small'} theme={'Primary'} onClick={saveButtonClicked} />
        </SidebarActionBar>
        </>}
    </div>
);
}

export default memo(ChangeKeyDataEditPanel);