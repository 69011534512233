export interface ChangeKeyDataEntity{
    key: string,
    versionIdentifier: {
        version?: string
    },
    cntrNo?: string | null,
    billCode?: string | null,
    firstInTmlDatetime?: Date | null,
    refCntrNo?: string | null,
    refBillCode?: string | null,
    refFirstInTmlDatetime?: Date | null,
    processStatus?: string | null,
    refVesselName?: string | null,
    vesselName?: string | null,
    refServiceCode?: string | null,
    serviceCode?: string | null,
    refConsortiumCode?: string | null,
    consortiumCode?: string | null,
    refVesselCode?: string | null,
    vesselCode?: string | null,
    refVoyageCode?: string | null,
    voyageCode?: string | null,
    requestBy?: string | null,
    refEtb?: Date | null,
    etb?: Date | null,
    refEtd?: Date | null,
    etd?: Date | null,
    cntrVisitId?: string | null,
    handlingTerminal?: string | null,
    cokdType?: string | null,
    opsDatetime?: Date | null,
    refCntrTspStatus?: string | null,
    cntrTspStatus?: string | null,
    refOffStdBackLength?: number | null,
    offStdBackLength?: number | null,
    refOffStdFrontLength?: number | null,
    offStdFrontLength?: number | null,
    refOffStdHeight?: number | null,
    offStdHeight?: number | null,
    refOffStdLeftWidth?: number | null,
    offStdLeftWidth?: number | null,
    refOffStdLength?: number | null,
    offStdLength?: number | null,
    refOffStdMeasurementMode?: string | null,
    offStdMeasurementMode?: string | null,
    refOffStdRightWidth?: number | null,
    offStdRightWidth?: number | null,
    refOffStdWidth?: number | null,
    offStdWidth?: number | null,
    refEmptyIndicator?: string | null,
    emptyIndicator?: string | null,
    refImdgCode1?: string | null,
    imdgCode1?: string | null,
    refImdgCode2?: string | null,
    imdgCode2?: string | null,
    refImdgCode3?: string | null,
    imdgCode3?: string | null,
    cokdSubType?: string | null,
    refCntrSize?: number | null,
    cntrSize?: number | null,
    chargeInfo?: string | null,
    oldChargeInfo?: string | null,


    [key: string]: string | boolean | null | Object | undefined
}


export const EMPTY_CHANGE_KEY_DATA_ENTITY : ChangeKeyDataEntity = {
    key: "",
    versionIdentifier: {},
    cntrNo: null,
    billCode: null,
    firstInTmlDatetime: null,
    refCntrNo: null,
    refBillCode: null,
    refFirstInTmlDatetime: null,
    processStatus: null,
    refVesselName: null,
    vesselName: null,
    refServiceCode: null,
    serviceCode: null,
    refConsortiumCode: null,
    consortiumCode: null,
    refVesselCode: null,
    vesselCode: null,
    refVoyageCode: null,
    voyageCode: null,
    requestBy: null,
    refEtb: null,
    etb: null,
    refEtd: null,
    etd: null,
    cntrVisitId: null,
    handlingTerminal: null,
    cokdType: null,
    opsDatetime: null,
    refCntrTspStatus: null,
    cntrTspStatus: null,
    refOffStdBackLength: null,
    offStdBackLength: null,
    refOffStdFrontLength: null,
    offStdFrontLength: null,
    refOffStdHeight: null,
    offStdHeight: null,
    refOffStdLeftWidth: null,
    offStdLeftWidth: null,
    refOffStdLength: null,
    offStdLength: null,
    refOffStdMeasurementMode: null,
    offStdMeasurementMode: null,
    refOffStdRightWidth: null,
    offStdRightWidth: null,
    refOffStdWidth: null,
    offStdWidth: null,
    refEmptyIndicator: null,
    emptyIndicator: null,
    refImdgCode1: null,
    imdgCode1: null,
    refImdgCode2: null,
    imdgCode2: null,
    refImdgCode3: null,
    imdgCode3: null,
    cokdSubType: null,
    refCntrSize: null,
    cntrSize: null,
    chargeInfo: null,
    oldChargeInfo: null,
}