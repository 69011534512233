import { EMPTY_CHANGE_KEY_DATA_MODEL } from 'presentation/model/ChangeKeyData/ChangeKeyDataModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: ChangeKeyDataProvider,
    useTracked: useChangeKeyDataTracked
} = createContainer(() => useState(EMPTY_CHANGE_KEY_DATA_MODEL), {concurrentMode: true});
export { ChangeKeyDataProvider, useChangeKeyDataTracked };

