import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ChangeKeyDataProvider } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import ChangeKeyDataMaintenance from "presentation/view/section/ChangeKeyData/ChangeKeyDataMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const ChangeKeyDataContMain = () => {
    return <ANAInfoWrapper permission={Permission.CHANGE_KEY_DATA}>
        <MessageBarWrapper>
            <ChangeKeyDataProvider>
                <GridStyles/>
                <ChangeKeyDataMaintenance/>
            </ChangeKeyDataProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default ChangeKeyDataContMain;