export interface ChangeKeyDataEnabledSearchCriteria {
    all: boolean,
    cntrNos:boolean,
    handlingStatus:boolean,
    createdDate:boolean,
    vesselCode:boolean,
    voyageCode:boolean,
    billCode:boolean,
    oldBillCode:boolean,
    cokdTypes:boolean,
    cokdSubTypes:boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_CHANGE_KEY_DATA_SEARCH_CRITERIA: ChangeKeyDataEnabledSearchCriteria = {
    all: true,
    cntrNos:true,
    handlingStatus:true,
    createdDate:true,
    vesselCode:true,
    voyageCode:true,
    billCode:true,
    oldBillCode:true,
    cokdTypes:true,
    cokdSubTypes:true,
}