import { ChangeKeyDataChargeInfoEntity } from "domain/entity/ChangeKeyData/ChangeKeyDataChargeInfoEntity";
import { ChangeKeyDataEntity } from "domain/entity/ChangeKeyData/ChangeKeyDataEntity";
import { ChangeKeyDataSearchCriteria, EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA } from "domain/entity/ChangeKeyData/ChangeKeyDataSearchCriteria";
import { ChangeKeyDataRepository } from "domain/repository/ChangeKeyData/ChangeKeyDataRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { ChangeKeyDataModel } from "presentation/model/ChangeKeyData/ChangeKeyDataModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";


interface ChangeKeyDataVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ChangeKeyDataModel>> | ((value: SetStateAction<ChangeKeyDataModel>) => void),
    ],
    changeKeyDataRepo: ChangeKeyDataRepository,
    parameterDetailRepo: ParameterDetailRepository
    
}

export const ChangeKeyDataVM = ({dispatch,changeKeyDataRepo,parameterDetailRepo}:ChangeKeyDataVMProps) => {
    const [changeKeyDataDispatch] = dispatch;

    const loadDropdownOption = async() => {

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CHANGE_KEY_DATA_HANDLING_STATUS").then(
            handlingStatusList => {
                const handlingStatusDropdownOption = handlingStatusList?.map((handlingStatus) => ({
                    dropdownLabel: handlingStatus.parameterDtlCode,
                    tagLabel: handlingStatus.parameterDtlCode,
                    value: handlingStatus.parameterDtlCode,
                })) ?? []

                changeKeyDataDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        handlingStatusDropdownOptions: handlingStatusDropdownOption,
                    }
                }))
            }
        )

    }

    const getChangeKeyDatas = async(searchCriteria: ChangeKeyDataSearchCriteria) => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                changeKeyDataChargeInfos: [],
                selectedChangeKeyDatas: [],
                changeKeyDatas:[]
            }
        })
        await changeKeyDataRepo.getChangeKeyDatas(searchCriteria).then((data) => {
            changeKeyDataDispatch(prevState => {
                return {
                    ...prevState,
                    changeKeyDatas: data,
                    searchCriteria: searchCriteria,
                    changeKeyDataChargeInfos: [],
                    selectedChangeKeyDatas: [],
                    isShowChargeInfoPanel:false,
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onSearchCriteriaResetClick = () => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA
            }
        })
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldName: string, toUpperCase:boolean=false) => {
        const val = toUpperCase ? e.target.value.toUpperCase() : e.target.value;
        changeKeyDataDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: fieldName === 'cntrNos' ? 
                    (val&&val.toString().includes(",") ? val.toString().split(",") : [val.toString()]) : 
                    e.target.value.toString(),
            }
        }))
    };

    const onSearchInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string, toUpperCase:boolean=false) => {
        let val = toUpperCase ? e.target.value.toUpperCase() : e.target.value;
        if(fieldName !== 'voyageCode'){
            val = val.replace(/\s+/g, '');
        }
        changeKeyDataDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: val,
            }
        }))
    };
    const onDropdownChange = (e: any) => {

        changeKeyDataDispatch(prevState => ({
            ...prevState,
            updateHandlingStatus: e.value
        }))
    };
    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        changeKeyDataDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onCloseClick = () => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false
            }
        }); 
    }
    const onEditCloseClick = (selectChangeKeyDatas: ChangeKeyDataEntity[]) => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                isShowEditPanel: false,
                updateHandlingStatus: "",
                changeKeyDataChargeInfos: selectChangeKeyDatas.length>1?[]:prevState.changeKeyDataChargeInfos,
                isShowChargeInfoPanel:selectChangeKeyDatas.length>1?false:true
            }
        }); 
    }
    const onEditSaveClick = async(updateHandlingStatus: string,selectChangeKeyDatas: ChangeKeyDataEntity[]) => {
        if(selectChangeKeyDatas && selectChangeKeyDatas.length > 0 && updateHandlingStatus){
            selectChangeKeyDatas = selectChangeKeyDatas.map((data) => {
                    let keyData = {...data};
                    keyData.processStatus = updateHandlingStatus;
                    return {...data, ...keyData};    
            })
            await changeKeyDataRepo.updateChangeKeyDatas(selectChangeKeyDatas).then((data) => {
                changeKeyDataDispatch(prevState => {
                    return {
                        ...prevState,
                        isShowEditPanel: false,
                        updateHandlingStatus: "",
                        isShowChargeInfoPanel:selectChangeKeyDatas.length>1?false:true
                        //selectedChangeKeyDatas: []
                    }
                })
            }).catch((error) => {
                return [];
            })
        }
    }

    const onSearchClick = async() => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });       
    }

    const onEditClick = async() => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                isShowChargeInfoPanel: false,
                isShowEditPanel: true,
                isShowCriteriaPanel: false,
            }
        });       
    }

    const onApplyClick = async(searchCriteria: ChangeKeyDataSearchCriteria,selectChangeKeyData: ChangeKeyDataEntity, allCharges: ChangeKeyDataChargeInfoEntity[], selectCharges: ChangeKeyDataChargeInfoEntity[]) => {
        return await changeKeyDataRepo.apply(selectChangeKeyData, allCharges, selectCharges).then(async (data) => {

            await getChangeKeyDatas(searchCriteria);
            return data;
        }).catch(async (error) => {
            await getChangeKeyDatas(searchCriteria);
            return error;
        })    
        // return msg;   
    }

    const updateSelectedCharges = async (rows:any[]) => {
        if(rows.length === 1){
            onShowLoading();
            changeKeyDataDispatch(prevState => {
                return {
                    ...prevState,
                    selectedChangeKeyDatas: rows,
                    isShowChargeInfoPanel: true,
                }
            })
            await getChangeKeyDataChargeInfos(rows[0]);
            onHideLoading();
        }else{
            changeKeyDataDispatch(prevState => {
                return {
                    ...prevState,
                    selectedChangeKeyDatas: rows,
                    isShowChargeInfoPanel: rows.length===1?true:false,
                    selectChargeInfos: []
                }
            })

        }
    }

    const updateChargeSelectedCharges = (rows:any[]) => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                selectChargeInfos: rows,
                // isShowCriteriaPanel: false,
                // isShowChargeInfoPanel: true      
            }
        })
    }



    const getChangeKeyDataChargeInfos = async(selectedChangeKeyData : ChangeKeyDataEntity) => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                changeKeyDataChargeInfos: [],
            }
        })
        await changeKeyDataRepo.getChangeKeyDataChargeInfos(selectedChangeKeyData.key).then((data) => {
            changeKeyDataDispatch(prevState => {
                return {
                    ...prevState,
                    isLoading: false,
                    isDoubleClick: false,
                    isShowEditPanel: false,
                    isShowChargeInfoPanel: true,
                    changeKeyDataChargeInfos: data,
                    selectChargeInfos:[]
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const handleRowClick = async(selectedChangeKeyData : ChangeKeyDataEntity) => {
            changeKeyDataDispatch(prevState => {
                return {
                    ...prevState,
                    isShowChargeInfoPanel: true,
                    selectedChangeKeyDatas: [selectedChangeKeyData]
                }
        })
    }
    const onRowDoubleClick = async(selectedChangeKeyData : ChangeKeyDataEntity) => {
        await changeKeyDataRepo.getChangeKeyDataChargeInfos(selectedChangeKeyData.key).then((data) => {
            changeKeyDataDispatch(prevState => {
                return {
                    ...prevState,
                    isDoubleClick: true,
                    isShowEditPanel: false,
                    // isShowChargeInfoPanel: true,
                    changeKeyDataChargeInfos: data,
                }
            })
        }).catch((error) => {
            return [];
        })
    }
    const onInfoCloseClick = () => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                isDoubleClick: false,
                isShowChargeInfoPanel: false,
                // changeKeyDataChargeInfos: [],
                // selectChargeInfos: []
            }
        }); 
    }
    const onShowLoading = () => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        return;
    }

    const handleLegend = () => {
        changeKeyDataDispatch(prevState => {
            return {
                ...prevState,
                isShowLegend: !prevState.isShowLegend,                      
            }
        })
    }

   
    return {
        getChangeKeyDatas: getChangeKeyDatas,
        loadDropdownOption: loadDropdownOption,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onTextAreaChange: onTextAreaChange,
        onDropdownChange: onDropdownChange,
        onSearchInputTextChange: onSearchInputTextChange,
        onDateRangeChange: onDateRangeChange,
        onSearchClick: onSearchClick,
        onCloseClick: onCloseClick,
        onApplyClick: onApplyClick,
        updateSelectedCharges: updateSelectedCharges,
        handleRowClick: handleRowClick,
        onInfoCloseClick: onInfoCloseClick,
        onRowDoubleClick: onRowDoubleClick,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onEditClick: onEditClick,
        onFieldChange: onFieldChange,
        onEditCloseClick: onEditCloseClick,
        handleLegend: handleLegend,
        updateChargeSelectedCharges: updateChargeSelectedCharges,
        onEditSaveClick: onEditSaveClick,
        getChangeKeyDataChargeInfos : getChangeKeyDataChargeInfos
    }
}