import { SelectionChangedEvent } from "ag-grid-community";
import { ChangeKeyDataEntity } from "domain/entity/ChangeKeyData/ChangeKeyDataEntity";
import { ChangeKeyDataSearchCriteria, EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA } from "domain/entity/ChangeKeyData/ChangeKeyDataSearchCriteria";
import moment from "moment";
import { INITIAL_CHANGE_KEY_DATA_COL_DEF, transferRowData } from "presentation/constant/ChangeKeyData/ChangeKeyDataColumnDefinition";
import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const ChangeKeyDataTablePanel:React.FC = () => {
    const [ changeKeyDataState ] = useChangeKeyDataTracked();
    const changeKeyDataVM = useChangeKeyDataVM();
    const gridRef: any = useRef(null);
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_CHANGE_KEY_DATA_COL_DEF.slice());
        
        if (!changeKeyDataState.selectedChangeKeyDatas ||
            changeKeyDataState.selectedChangeKeyDatas.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    useEffect(() => {
        const initialScreen = async() => {   
            try {    
                changeKeyDataVM.onShowLoading();
                const cacheSearchCriteria = localStorage.getItem("ChangeKeyDataSearchCriteria");
                let results;
                if (cacheSearchCriteria) {
                    results = await Promise.allSettled([
                        changeKeyDataVM.getChangeKeyDatas(JSON.parse(cacheSearchCriteria) as ChangeKeyDataSearchCriteria),
                    ]).finally(() => {
                        changeKeyDataVM.onHideLoading();
                    });
                }else{
                    console.log("fromDate:"+moment().subtract(10, "days").startOf("day").toDate());
                    console.log("toDate:"+moment().endOf("day").toDate());     
                    let newSearchCriteria = {...EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA} 
                    newSearchCriteria = { ...newSearchCriteria, 
                        createdDateFrom: moment().subtract(10, "days").startOf("day").toDate(), 
                        createdDateTo: moment().endOf("day").toDate(),
                    };
    
                    results = await Promise.allSettled([
                        changeKeyDataVM.getChangeKeyDatas(newSearchCriteria),
                    ]);
                }
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        console.log(`Load change key data successful!`);
                      } else if (index === 1 && result.status === 'rejected') {
                        console.error(`Load dropdown options successful!`);
                      }
                })
            } catch(error) {
            }
        }
        initialScreen().then((data) => {            
            changeKeyDataVM.onHideLoading();
        }).catch(error => {            
            changeKeyDataVM.onHideLoading();
        });
    }, [changeKeyDataVM])


    
    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        changeKeyDataVM.updateSelectedCharges(selectedRows);
      }, [changeKeyDataVM])

    const handleRowClick = useCallback((data: ChangeKeyDataEntity) => {
        changeKeyDataVM.handleRowClick(data);
      }, [changeKeyDataVM])


    const memoChangeKeyDataTable = useMemo(() => {

        return (
        <HPHTable
            id='change-key-data-table'
            isNewColumnSetting={true}
            columns={INITIAL_CHANGE_KEY_DATA_COL_DEF.slice()}
            data={transferRowData(changeKeyDataState.changeKeyDatas??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            isRowHighligted={true}
            onRowClick={(e: any) => handleRowClick(e.data)}
            onSelectionChanged={handleSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
        />
        );
    },[changeKeyDataState.changeKeyDatas, handleRowClick, handleSelectionChange])

    return <><TableWrapper>
        {/* {isLoading && <Loader Indicator="Spinner" size="Medium" /> } */}
        {memoChangeKeyDataTable}</TableWrapper></>;
}

export default memo(ChangeKeyDataTablePanel);
