import { ChangeKeyDataRepoImpl } from "domain/repository/ChangeKeyData/ChangeKeyDataRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { ChangeKeyDataVM } from "presentation/viewModel/ChangeKeyData/ChangeKeyDataVM";
import { useMemo } from "react";

export const useChangeKeyDataVM = () => {
    const [, setChangeKeyDataState] = useChangeKeyDataTracked();
    const changeKeyDataVM = useMemo(() =>
        ChangeKeyDataVM({
            dispatch: [setChangeKeyDataState],
            parameterDetailRepo: ParameterDetailRepoImpl(),
            changeKeyDataRepo: ChangeKeyDataRepoImpl(),

        }), [setChangeKeyDataState])

    return changeKeyDataVM
}

