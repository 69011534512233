import { ChangeKeyDataConstant } from "presentation/constant/ChangeKeyData/ChangeKeyDataConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ChangeKeyDataTitleBar:React.FC = () => {
    const changeKeyDataVM = useChangeKeyDataVM();
    const messageBarVM = useMessageBarVM();
    const [changeKeyDataState] = useChangeKeyDataTracked();
    const {selectedChangeKeyDatas} = changeKeyDataState;
    // const {allFormState, selectedChangeKeyDatas,changeKeyDataChargeInfos,selectChargeInfos,searchCriteria} = changeKeyDataState;
    const CHANGEKEYDATA_CONSTANT = ChangeKeyDataConstant.Charge;
    // const [, setIsSave] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const {allowUpdate} = anainfoState;

    const handleSearch = useCallback(() => {
        changeKeyDataVM.onSearchClick();
    }, [changeKeyDataVM]);

    const handleEdit = useCallback(() => {
        if(selectedChangeKeyDatas.length === 0){
            messageBarVM.showWarining(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
            return;
        }
        changeKeyDataVM.onEditClick();
    }, [changeKeyDataVM, messageBarVM, selectedChangeKeyDatas]);

    // const handleApply = useCallback(async() => {
    //     setIsSave(true);
    //     if(selectedChangeKeyDatas.length === 0){
    //         messageBarVM.showWarining(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
    //         return;
    //     }
    //     if(selectedChangeKeyDatas.length > 1){
    //         messageBarVM.showWarining(MessageConstant.charge.SEL_ONE_CHANGE_KEY_DATA);
    //         return;
    //     }
    //     if(selectChargeInfos.length < 1){
    //         messageBarVM.showWarining(MessageConstant.charge.SEL_ONE_CHARGE_DTL_DATA_APPLY);
    //         return;
    //     }
    //     changeKeyDataVM.onShowLoading();
    //     changeKeyDataVM.onApplyClick(selectedChangeKeyDatas[0],changeKeyDataChargeInfos,selectChargeInfos).then((data) => {
    //         if (allFormState && allFormState["applyChangeKeyDataFail"]) {
    //             messageBarVM.showError(allFormState["applyChangeKeyDataFail"]?.toString());
    //         }else{
    //             changeKeyDataVM.onHideLoading();
    //             messageBarVM.showSuccess("Apply Successful");
    //         }
    //         changeKeyDataVM.getChangeKeyDatas(searchCriteria);
    //     }).finally(() => {
    //         changeKeyDataVM.onHideLoading();
    //     })
    // }, [allFormState, changeKeyDataChargeInfos, changeKeyDataVM, messageBarVM, searchCriteria, selectChargeInfos, selectedChangeKeyDatas]);


    const isDisableEdit = () => {
        if (changeKeyDataState.selectedChangeKeyDatas.length > 0 && !changeKeyDataState.isShowEditPanel) return false;
        return true;
    }
    // const isDisableApply = () => {
    //     if (changeKeyDataState.selectedChangeKeyDatas.length > 0 && changeKeyDataState.selectChargeInfos.length > 0) return false;
    //     return true;
    // }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{CHANGEKEYDATA_CONSTANT.CHANGE_KEY_DATA}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={changeKeyDataState.isShowEditPanel} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            {(allowUpdate) && <><div className="add-seperator"/>
            <IconButton fileName='Icon-pen' disabled={isDisableEdit()} size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} /></>}
            {/* <div className="add-seperator"/>
            <HPHButton label={CHANGEKEYDATA_CONSTANT.APPLY_BUT} disabled={isDisableApply()} size={'Small'} theme={'Primary'} onClick={handleApply} /> */}
        </StyledAction>
    </Sidebarheader>
}

export default memo(ChangeKeyDataTitleBar);