import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { ChangeKeyDataConstant } from "./ChangeKeyDataConstant";

const CONTAINER_CONSTANT = ChangeKeyDataConstant.Charge;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_CHANGE_KEY_DATA_CHARGE_INFO_COL_DEF: any[] = [
    {
        headerName: CONTAINER_CONSTANT.DTL_STATE,
        field: 'dtlState',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.SUB_CHARGE_TYPE,
        field: 'subChargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.ALTERABLE_CHARGE_ON_COMPANY,
        field: 'alterableChargeOnCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 280,
    },
    {
        headerName: CONTAINER_CONSTANT.ALTERABLE_BILL_TO_COMPANY,
        field: 'alterableBillToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.CHARGE_ON_COMPANY,
        field: 'chargeOnCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.OPERATION_TYPE,
        field: 'operationType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.TARIFF_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: CONTAINER_CONSTANT.FIRST_IN_TML,
        field: 'inTmlDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: "date"
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
    cellRenderers['dtlState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    externalFnctions['dtlState'] = calculateStateColor;

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}