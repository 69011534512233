import { ChangeKeyDataChargeInfoEntity } from "domain/entity/ChangeKeyData/ChangeKeyDataChargeInfoEntity";
import { ChangeKeyDataEntity } from "domain/entity/ChangeKeyData/ChangeKeyDataEntity";
import { ChangeKeyDataSearchCriteria } from "domain/entity/ChangeKeyData/ChangeKeyDataSearchCriteria";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { ChangeKeyDataRepository } from "./ChangeKeyDataRepo";

export const ChangeKeyDataRepoImpl = () : ChangeKeyDataRepository => {
    const getChangeKeyDatas = async(searchCriteria: ChangeKeyDataSearchCriteria) : Promise<ChangeKeyDataEntity[]> => {
        const url = "/v1/changeKeyDataSearch";
        return axiosPostData(chargeAxiosInstance, `${url}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const getChangeKeyDataChargeInfos = async(key: string) : Promise<ChangeKeyDataChargeInfoEntity[]> => {
        const newUrl = "/v1/changeKeyDataChargeInfoSearch";
        return axiosGetData(chargeAxiosInstance, `${newUrl}/${key}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const updateChangeKeyDatas = async(selectChangeKeyDatas: ChangeKeyDataEntity[]) : Promise<boolean> => {
        const newUrl = "/v1/updateChangeKeyData";
        
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, selectChangeKeyDatas).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const apply = async(selectChangeKeyData: ChangeKeyDataEntity, allCharges: ChangeKeyDataChargeInfoEntity[], selectCharges: ChangeKeyDataChargeInfoEntity[]) : Promise<string> => {
        const newUrl = "/v1/applyChangeKeyData";
        let applyEntity = {
            changeKeyData: selectChangeKeyData,
            allCharges: allCharges,
            selectCharges: selectCharges
        }
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, applyEntity).then(res => {
            let data = res.data;
            if(!res.sucess || res.code === "500"){
                return data?data:res.msg;
            }
            return data;
        }).catch(err => {
            return [];
        });
    }
    return {
        getChangeKeyDatas: getChangeKeyDatas,
        getChangeKeyDataChargeInfos: getChangeKeyDataChargeInfos,
        apply: apply,
        updateChangeKeyDatas: updateChangeKeyDatas
    }
}