export interface ChangeKeyDataSearchCriteria{
    
    cntrNos?: string[] | null,
    handlingStatus?: string[] | null,
    createdDateFrom?: Date | null,
    createdDateTo?: Date | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    billCode?: string | null,
    oldBillCode?: string | null,
    cokdTypes?: string[] | null,
    cokdSubTypes?: string[] | null

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA : ChangeKeyDataSearchCriteria = {

    cntrNos: [],
    handlingStatus: [],
    createdDateFrom: null,
    createdDateTo: null,
    vesselCode: null,
    voyageCode: null,
    billCode: null,
    oldBillCode: null,
    cokdTypes: [],
    cokdSubTypes: [],
}