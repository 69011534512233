import { ChangeKeyDataConstant } from "presentation/constant/ChangeKeyData/ChangeKeyDataConstant";
import { cokdSubTypeDropdownOption, cokdTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { memo, useMemo } from "react";
import { HPHInputTextarea, InputDropdown, InputField } from "veronica-ui-component/dist/component/core";

const ChangeKeyDataSearchForm = () => {    
    const CONTAINER_CONSTANT = ChangeKeyDataConstant.Charge;
    const [changeKeyDataState] = useChangeKeyDataTracked();
    const changeKeyDataSearchCriteria = changeKeyDataState.searchCriteria;
    const changeKeyDataVM = useChangeKeyDataVM();
    const memoCntrNo = useMemo(() =>
        <div className='flex-row-item'>
            <HPHInputTextarea
                label={CONTAINER_CONSTANT.CNTR_NO}
                width="390px"
                rows={3}               
                resizable={false}
                helpIcon={true}
                toolTipText="Use comma ( , ) as delimiter"
                value={changeKeyDataSearchCriteria.cntrNos?.join(",")}
                onChange={(e) => {changeKeyDataVM.onTextAreaChange(e, "cntrNos", true)}} />
        </div>
    , [changeKeyDataSearchCriteria.cntrNos, changeKeyDataVM, CONTAINER_CONSTANT.CNTR_NO])
    const memohandlingStatus = useMemo(() =>
            <div className='flex-row-item'>
                <InputDropdown
                    label={CONTAINER_CONSTANT.PROCESS_STATUS}
                    width='390px'
                    inputType="freeText"
                    mode={'multiple'}
                    sort={false}
                    value={changeKeyDataSearchCriteria.handlingStatus?.map((item) => ({
                        value: item
                    }))}
                    onChange={(e) => changeKeyDataVM.onMultipleDropdownChange(e, 'handlingStatus')}
                    options={changeKeyDataState.dynamicOptions.handlingStatusDropdownOptions}/>
            </div>
        , [CONTAINER_CONSTANT.PROCESS_STATUS, changeKeyDataSearchCriteria.handlingStatus, changeKeyDataState.dynamicOptions.handlingStatusDropdownOptions, changeKeyDataVM])
    
    const memoCokdType = useMemo(() =>
        <div className='flex-row-item'>
            <InputDropdown
                label={CONTAINER_CONSTANT.COKD_TYPE}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={changeKeyDataSearchCriteria.cokdTypes?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => changeKeyDataVM.onMultipleDropdownChange(e, 'cokdTypes')}
                options={cokdTypeDropdownOption}/>
        </div>
    , [CONTAINER_CONSTANT.COKD_TYPE, changeKeyDataSearchCriteria.cokdTypes, changeKeyDataVM])
    const memoCokdSubType = useMemo(() =>
        <div className='flex-row-item'>
            <InputDropdown
                label={CONTAINER_CONSTANT.COKD_SUB_TYPE}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={changeKeyDataSearchCriteria.cokdSubTypes?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => changeKeyDataVM.onMultipleDropdownChange(e, 'cokdSubTypes')}
                options={cokdSubTypeDropdownOption}/>
        </div>
    , [CONTAINER_CONSTANT.COKD_SUB_TYPE, changeKeyDataSearchCriteria.cokdSubTypes, changeKeyDataVM])

    const memoVesselCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField                
                maxLength={6}
                style={{"text-transform": "uppercase", width:"180px"}}
                label={CONTAINER_CONSTANT.VESSEL_CODE}
                type="text"
                value={changeKeyDataSearchCriteria.vesselCode || ''}
                onChange={(e: any) => changeKeyDataVM.onSearchInputTextChange(e, 'vesselCode', true)}/>
        </div>
    , [CONTAINER_CONSTANT.VESSEL_CODE, changeKeyDataSearchCriteria.vesselCode, changeKeyDataVM])
    const memoVoyageCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                maxLength={10}
                style={{"text-transform": "uppercase", width:"180px"}}
                label={CONTAINER_CONSTANT.VOYAGE_CODE}
                type="text"
                value={changeKeyDataSearchCriteria.voyageCode || ''}
                onChange={(e: any) => changeKeyDataVM.onSearchInputTextChange(e, 'voyageCode', true)}/>
        </div>
    , [CONTAINER_CONSTANT.VOYAGE_CODE, changeKeyDataSearchCriteria.voyageCode, changeKeyDataVM])
    const memoBillCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={CONTAINER_CONSTANT.BILL_CODE}
                type="text"
                value={changeKeyDataSearchCriteria.billCode || ''}
                onChange={(e: any) => changeKeyDataVM.onSearchInputTextChange(e, 'billCode', true)}/>
        </div>
    , [CONTAINER_CONSTANT.BILL_CODE, changeKeyDataSearchCriteria.billCode, changeKeyDataVM])
    const memoOldBillCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={CONTAINER_CONSTANT.OLD_BILL_CODE}
                type="text"
                value={changeKeyDataSearchCriteria.oldBillCode || ''}
                onChange={(e: any) => changeKeyDataVM.onSearchInputTextChange(e, 'oldBillCode', true)}/>
        </div>
    , [CONTAINER_CONSTANT.OLD_BILL_CODE, changeKeyDataSearchCriteria.oldBillCode, changeKeyDataVM])
    
    const memoCreatedDateFromTo = useMemo(() =>
            <div className='im-flex-row-item'>
                <DateRangePickerComponent
                    label={CONTAINER_CONSTANT.CREATED_DATE_RANGE}
                    width='390px'
                    fieldNames={{startField:"createdDateFrom", endField:"createdDateTo"}}
                    dateRange={{startDate:changeKeyDataSearchCriteria.createdDateFrom, endDate:changeKeyDataSearchCriteria.createdDateTo}}
                    onDatesChange={changeKeyDataVM.onDateRangeChange}
                />                
            </div>
        , [CONTAINER_CONSTANT.CREATED_DATE_RANGE, changeKeyDataSearchCriteria.createdDateFrom, changeKeyDataSearchCriteria.createdDateTo, changeKeyDataVM.onDateRangeChange])

   

    return <>        
        
        <CriteriaItemContainer>
            {changeKeyDataState.enabledSearchCriteria.handlingStatus ? memohandlingStatus : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {changeKeyDataState.enabledSearchCriteria.cntrNos ? memoCntrNo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {changeKeyDataState.enabledSearchCriteria.cokdTypes ? memoCokdType : null}
            {changeKeyDataState.enabledSearchCriteria.cokdSubTypes ? memoCokdSubType : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {changeKeyDataState.enabledSearchCriteria.vesselCode ? memoVesselCode : null}
            {changeKeyDataState.enabledSearchCriteria.voyageCode ? memoVoyageCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {changeKeyDataState.enabledSearchCriteria.billCode ? memoBillCode : null}
            {changeKeyDataState.enabledSearchCriteria.oldBillCode ? memoOldBillCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {changeKeyDataState.enabledSearchCriteria.createdDate ? memoCreatedDateFromTo : null}
        </CriteriaItemContainer>

    </>;
}
export default memo(ChangeKeyDataSearchForm);